import React, { useEffect, useState } from "react";

export function ImageContainer({ src, name, className }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
    const img = new Image();
    img.onload = () => {
      setTimeout(() => {
        setImageLoaded(true);
      }, 500);
    };
    img.src = src;
  }, [src]);

  return (
    <div className="image-container">
      {!imageLoaded && !isError && (
        <div className="">
          <img />
        </div>
      )}
      {isError && imageLoaded && <div>No image</div>}
      {imageLoaded && !isError && (
        <img
          src={src}
          alt={name}
          height={"100%"}
          className={`${className}`}
          width={"100%"}
          loading="lazy"
          style={{
            objectFit: "contain",
          }}
          onError={() => setIsError(true)}
        />
      )}
    </div>
  );
}

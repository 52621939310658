import { Modal } from "antd";
import PlayIcon from "assets/images/tutorial/timeless-step/PlayIcon";
import { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { ImageContainer } from "./ImageContainer";

const VideoIphoneContainer = ({ url, thumbnail, isRenderModal }) => {
  const videoRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  function handleOpenVideo() {
    if (!videoRef.current) return;
    videoRef.current.seekTo(0);
    setIsFullScreen(true);
  }

  if (isRenderModal)
    return (
      <div className="video-group">
        <ImageContainer
          src={require(`../../assets/images/tutorial/intract-step/step-2.png`)}
          alt="iphone-1"
          className="iphone"
        />
        <PlayIcon className="play-icon" onClick={handleOpenVideo} />
        <Modal
          forceRender
          open={isFullScreen}
          centered
          width={962}
          onCancel={() => {
            setIsFullScreen(false);
          }}
          footer={null}
          maskStyle={{
            backgroundColor: "rgba(0,0,0,0.8)",
            backdropFilter: "blur(50px)",
          }}
          className="video-modal"
        >
          <div className="video-step-2">
            <ReactPlayer
              url={url}
              muted
              playsinline
              width="100%"
              height="100%"
              controls
              playIcon={<PlayIcon />}
              playing
              ref={videoRef}
            />
          </div>
        </Modal>
      </div>
    );

  return (
    <div className="video-group">
      <ImageContainer
        src={require(`../../assets/images/tutorial/ip-15-pro.png`)}
        alt="iphone-1"
        className="iphone"
      />
      <div className="video-step-2" ref={videoRef}>
        <ReactPlayer
          url={url}
          muted
          key={url}
          width="100%"
          height="100%"
          controls
          light={thumbnail}
          playing
          playIcon={<PlayIcon />}
        />
      </div>
    </div>
  );
};

export default VideoIphoneContainer;

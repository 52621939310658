import { useState } from "react";
import TutorialPage from "../TutorialPage";
import Quest from "./intract";
import Timeless from "./timeless";

const LineaStep = () => {
  const [tabActive, setTabActive] = useState("Timeless");

  const renderStep = () => {
    switch (tabActive) {
      case "Timeless":
        return <Timeless tabActive={tabActive} setTab={setTabActive} />;
      case "Layer3":
        return <Quest tabActive={tabActive} setTab={setTabActive} />;
      // case "Linea":
      //   return <Linea tabActive={tabActive} setTab={setTabActive} />;
      default:
        return <Timeless tabActive={tabActive} setTab={setTabActive} />;
    }
  };
  return <TutorialPage>{renderStep()}</TutorialPage>;
};

export default LineaStep;

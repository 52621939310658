import React from "react";

const TelegramLogo = ({ className }) => {
  return (
    <svg
      width="28px"
      height="28px"
      className={className}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="100%"
          x2="50%"
          y2="0%"
          id="linearGradient-i9uijh_7sk-1"
        >
          <stop stopColor="#1D93D2" offset="0%"></stop>
          <stop stopColor="#38B0E3" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Everything-you-need-at-your-finger-tips-Copy-18"
          transform="translate(-643, -594)"
          fillRule="nonzero"
        >
          <g id="Group-10" transform="translate(455, 478)">
            <g id="Group-7-Copy-7" transform="translate(59, 39)">
              <g id="telegram" transform="translate(129, 77)">
                <circle
                  id="Oval"
                  fill="url(#linearGradient-i9uijh_7sk-1)"
                  cx="14"
                  cy="14"
                  r="14"
                ></circle>
                <path
                  d="M10,14.7553385 L11.6076308,19.5512713 C11.6076308,19.5512713 11.808627,20 12.023851,20 C12.2390751,20 15.4402218,16.410657 15.4402218,16.410657 L19,9 L10.057363,13.5173486 L10,14.7553385 Z"
                  id="Path"
                  fill="#C8DAEA"
                ></path>
                <path
                  d="M11.3308336,16 L11.005694,19.5205613 C11.005694,19.5205613 10.8695946,20.5992983 11.928172,19.5205613 C12.9867494,18.4418243 14,17.6099847 14,17.6099847"
                  id="Path"
                  fill="#A9C6D8"
                ></path>
                <path
                  d="M9.68793581,14.9946351 L6.30035395,13.9241943 C6.30035395,13.9241943 5.89550055,13.7648958 6.02586335,13.4036697 C6.05269934,13.3291808 6.10683403,13.265798 6.26877538,13.1568692 C7.01937358,12.6494699 20.1617245,8.06829263 20.1617245,8.06829263 C20.1617245,8.06829263 20.5328015,7.94702386 20.7516537,8.02768274 C20.8618807,8.06077421 20.9451962,8.14886285 20.9696962,8.25821679 C20.9933435,8.3530855 21.0032323,8.45068918 20.999077,8.54820733 C20.9980359,8.63256821 20.9875097,8.71075908 20.9795283,8.83337403 C20.899483,10.0858863 18.504139,19.43379 18.504139,19.43379 C18.504139,19.43379 18.3608209,19.9807896 17.8473511,19.999524 C17.592396,20.0075111 17.3450331,19.9147097 17.1619922,19.7424028 C16.1543699,18.9018229 12.6717053,16.6319318 11.9021369,16.1327218 C11.8670168,16.1095115 11.8439823,16.0726882 11.8389797,16.031758 C11.8282222,15.9791446 11.8872151,15.9139668 11.8872151,15.9139668 C11.8872151,15.9139668 17.9514563,10.6862845 18.1128193,10.1374901 C18.1253119,10.0949731 18.0781176,10.073995 18.0147291,10.0926172 C17.6119578,10.2363224 10.6297405,14.512589 9.85913096,14.9845388 C9.80366031,15.0008151 9.74503133,15.0042727 9.68793581,14.9946351 Z"
                  id="Path"
                  fill="#FFFFFF"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TelegramLogo;

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
// import './index.scss';
import "styles/global.css";
import "styles/font.css";
import "assets/fonts/Montserrat-ExtraBold.ttf";
import "assets/fonts/Montserrat-Bold.ttf";
import "assets/fonts/Montserrat-Medium.ttf";
import AppRoutes from "routes";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<AppRoutes />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from "styled-components";

export const CustomPageLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
import React from "react";

const PlayIcon = ({ className, onClick }) => {
  return (
    <img
      className={className}
      src={require("../playIcon.png")}
      width={50}
      height={50}
      onClick={() => {
        onClick();
      }}
    />
  );
};

export default PlayIcon;

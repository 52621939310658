import React from "react";
import { Link } from "react-router-dom";
import TutorialPage from "../TutorialPage";

const StartStep = () => {
  return (
    <TutorialPage>
      <div className="start-step fade-in">
        <div className="left">
          <div className="title">A Guided Tour of the</div>
          <div className="sub-title">Linea Park x Timeless</div>
          <Link to="lineapark-feature" className="button cursor-pointer">
            Next
          </Link>
        </div>
        <div className="right">
          <img
            src={require("../../../assets/images/tutorial/step/step-1.png")}
            alt="iphone-1"
            className="iphone"
          />
        </div>
      </div>
    </TutorialPage>
  );
};

export default StartStep;

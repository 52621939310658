import { ImageContainer } from "pages/tutorial/ImageContainer";
import VideoIphoneContainer from "pages/tutorial/VideoIphoneContainer";
import { Fragment, useState } from "react";
import Steps from "../Steps";
import Tab from "../Tab";

const Step1 = () => {
  return (
    <Fragment>
      <div className="step-sub-title">
        <div style={{ marginBottom: 10 }} />
        Go to Layer3 and add your Timeless X wallet to your profile.
      </div>
      <div style={{ marginBottom: 10 }} />
      <a
        type="button"
        rel="noreferrer"
        href="https://layer3.xyz/settings/profile"
        target="_blank"
        className="go-to-layer3-button"
      >
        Navigate to Layer3
      </a>
    </Fragment>
  );
};

const Step2 = () => {
  return (
    <div className="step-sub-title">
      <div style={{ marginBottom: 10 }} />
      <p>
        Add your Timeless X wallet to your Layer3 profile via WalletConnect by
        following the video guide to the right.
      </p>
      <div>Need a video guide for desktop?</div>
      <div style={{ marginBottom: 10 }} />
      <a
        type="button"
        rel="noreferrer"
        href="https://timelessx.gitbook.io/timeless-x/help-center/linea-park-guide/add-a-wallet-to-your-layer3-account"
        target="_blank"
        className="go-to-layer3-button"
      >
        Reference Gitbook
      </a>
    </div>
  );
};

const Step3 = () => {
  return (
    <div className="step-sub-title">
      Mint your Lucky Cat NFT and complete the task.
      <div style={{ marginBottom: 24 }} />
      Note: at first logon, you’ll be prompted with eligible transactions to
      mint as NFT tickets for a chance to win a Voyage NFT.
    </div>
  );
};

const Step4 = () => {
  return (
    <div className="step-sub-title">
      <p>
        Every on-chain transaction you have on Linea is a ticket to Lucky Cat
        Draw.
      </p>
      <div>
        Mint your ticket NFT by following the video guide and verify the task
        with the Timeless X wallet connected to your Layer3 profile.
      </div>
    </div>
  );
};

// const Step5 = () => {
//   return (
//     <div className="step-sub-title">
//       <p>
//         Follow the instructions on the pinned post on our Twitter profile and
//         grab a chance to earn more.
//       </p>
//       <a
//         type="button"
//         rel="noreferrer"
//         href="#"
//         className="go-to-layer3-button"
//       >
//         Go to Pinned Post
//       </a>
//     </div>
//   );
// };

const Quest = ({ tabActive, setTab }) => {
  const [type, setType] = useState("image");
  const [steps, setSteps] = useState([
    {
      id: 1,
      title: "Navigate to Layer3.xyz",
      content: <Step1 />,
      isVisible: true,
      src: require("assets/images/tutorial/intract-step/step-1.png"),
      type: "image",
    },
    {
      id: 2,
      title: "Add Your Wallet to Layer3 Profile",
      content: <Step2 />,
      isVisible: false,
      src: require("../../../../assets/images/tutorial/intract-step/add-to-layer3.mp4"),
      thumbnail: require("assets/images/tutorial/intract-step/step-2.png"),
      type: "video",
    },
    {
      id: 3,
      title: (
        <span>
          Complete On-chain Task <br /> (20 LXP)
        </span>
      ),
      content: <Step3 />,
      isVisible: false,
      src: require("../../../../assets/images/tutorial/intract-step/complete-on-chain-task.mp4"),
      thumbnail: require("assets/images/tutorial/intract-step/step-3.jpeg"),
      type: "video",
    },
    {
      id: 4,
      title: (
        <span>
          Complete Bonus Task <br /> (20 LXP)
        </span>
      ),
      content: <Step4 />,
      isVisible: false,
      src: require("assets/images/tutorial/intract-step/eligible_transactions.mp4"),
      thumbnail: require("assets/images/tutorial/intract-step/step-4.png"),
      type: "video",
    },
    // {
    //   id: 5,
    //   title: "Up Your Game, Up Your XPs",
    //   content: <Step5 />,
    //   isVisible: false,
    //   src: require("assets/images/tutorial/intract-step/step-5.png"),
    //   type: "image",
    // },
  ]);

  const changeStep = (clickedIndex) => {
    const updatedSteps = steps.map((step, index) => {
      return {
        ...step,
        isVisible: index === clickedIndex,
      };
    });
    const visibleStep = updatedSteps.find((step) => step.isVisible);
    if (visibleStep) {
      setType(visibleStep.type);
    }
    setSteps(updatedSteps);
  };

  const renderVisibleImage = () => {
    const visibleStep = steps.find((step) => step.isVisible);

    if (visibleStep.type === "video")
      return { src: visibleStep.src, thumbnail: visibleStep.thumbnail };

    if (visibleStep.type === "image") return { src: visibleStep.src };

    return null;
  };

  return (
    <div className="intract-tab">
      <div className="left">
        <Tab tabActive={tabActive} setTab={setTab} />
        <Steps
          steps={steps}
          changeStep={(index) => {
            changeStep(index);
          }}
        />
      </div>
      <div className="right">
        {type === "video" && (
          <VideoIphoneContainer
            url={renderVisibleImage().src}
            thumbnail={renderVisibleImage().thumbnail}
            isRenderModal={false}
          />
        )}
        {type === "image" && (
          <ImageContainer
            src={renderVisibleImage().src}
            alt="iphone-1"
            className="iphone"
          />
        )}
      </div>
    </div>
  );
};

export default Quest;

import React from "react";

const Tabs = [
  { id: 0, name: "Timeless" },
  { id: 1, name: "Layer3" },
  // { id: 2, name: "Linea" },
];

const Tab = ({ tabActive, setTab }) => {
  return (
    <div className="tabs">
      {Tabs.map((tab) => {
        return (
          <button
            className={`tab-item ${
              tabActive === tab.name ? "--active fade-in" : ""
            }`}
            key={tab.id}
            onClick={() => {
              setTab(tab.name);
            }}
          >
            {tab.name}
          </button>
        );
      })}
    </div>
  );
};

export default Tab;

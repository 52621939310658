import React from "react";

const XLogo = ({ className }) => {
  return (
    <svg
      width="20px"
      className={className}
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Everything-you-need-at-your-finger-tips-Copy-18"
          transform="translate(-527, -598)"
          fill="#000000"
          fillRule="nonzero"
        >
          <g id="Group-10" transform="translate(455, 478)">
            <g id="Group-5" transform="translate(60, 111)">
              <path
                d="M23.9046667,17.4696087 L31.3513333,9 L29.5873333,9 L23.1186667,16.3525396 L17.956,9 L12,9 L19.8086667,20.1194005 L12,29 L13.764,29 L20.5906667,21.2338052 L26.044,29 L32,29 M14.4006667,10.301582 L17.1106667,10.301582 L29.586,27.7623647 L26.8753333,27.7623647"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default XLogo;

import React from "react";
import "../../index.scss";
import AppleLogoButton from "assets/images/tutorial/apple-logo";
import GoogleLogoButton from "assets/images/tutorial/google-logo";
import { Link } from "react-router-dom";
import GuideIcon from "assets/svg/GuideIcon";

const TutorialPage = ({ children }) => {
  const pathname = window.location.pathname;
  const isVisibleFooter =
    pathname === "/lineapark-feature" || pathname === "/send-via-link";
  return (
    <div className="tutorial">
      <img
        src={require("../../assets/images/tutorial/background-tutorial.png")}
        alt="background"
        className="background"
      />
      <div className="content-tutorial">
        <div className="header">
          <div className="logo cursor-pointer">
            <Link to="/lineapark" className="disable-long-press">
              <img
                src={require("../../assets/images/tutorial/wordmark.png")}
                alt="header-logo"
              />
            </Link>
          </div>
          <div className="group-button">
            <a
              href="https://apps.apple.com/us/app/timeless-x/id6470180600"
              className="black-button button-link disable-long-press"
              target="_blank"
              rel="noreferrer"
            >
              <AppleLogoButton />
            </a>
            <a
              className="black-button button-link disable-long-press"
              href="https://play.google.com/store/apps/details?id=xyz.timelesswallet.aa"
              target="_blank"
              rel="noreferrer"
            >
              <GoogleLogoButton />
            </a>
          </div>
        </div>
        <div className="box-content container">
          <div className="window-platter hidden-scroll-bar">
            <div className="header-content">
              <img
                src={require("../../assets/images/tutorial/linear-logo.png")}
                alt="linear-logo"
                className="linear-logo disable-long-press"
              />
              {isVisibleFooter && (
                <a
                  target="_blank"
                  href="https://timelessx.gitbook.io/timeless-x/help-center/linea-park-guide"
                  rel="noreferrer"
                  className="guide-button"
                >
                  <GuideIcon className={"icon"} />
                  <span>Linea Park Guide</span>
                </a>
              )}
            </div>
            <div className="content-step hidden-scroll-bar">{children}</div>
          </div>
        </div>
        <div
          className="footer disable-long-press"
          style={{ visibility: isVisibleFooter ? "visible" : "hidden" }}
        >
          <div className="share-text">Questions? Share on</div>
          <a
            className="telegram-contact disable-long-press"
            href="https://t.me/timelessfrens"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("../../assets/images/tutorial/telegram-icon.png")}
              alt="telegram-icon"
              className="telegram-icon disable-long-press"
            />
            <div className="telegram-text">@TimelessFrens</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TutorialPage;

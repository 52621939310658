import TelegramLogo from "assets/svg/TelegramLogo";
import XLogo from "assets/svg/XLogo";
import { ImageContainer } from "pages/tutorial/ImageContainer";
import VideoIphoneContainer from "pages/tutorial/VideoIphoneContainer";
import { Fragment, useState } from "react";
import Steps from "../Steps";
import Tab from "../Tab";

const Step1 = () => {
  return (
    <div>
      <div
        className="step-sub-title"
        style={{ marginTop: 10, marginBottom: 24 }}
      >
        <div>
          Simplify your Web3 experience with Timeless X — available on iOS and
          Android.
        </div>
      </div>
      <div>
        <div className="step-sub-title">Rate us 5 Stars.</div>
        <div className="group-button-download">
          <a
            rel={"noreferrer"}
            className="disable-long-press"
            href="https://apps.apple.com/us/app/timeless-x/id6470180600"
            target="_blank"
          >
            <img
              src={require("../../../../assets/images/tutorial/download-apple.png")}
              alt="download-apple"
              className="download-apple"
            />
          </a>
          <a
            rel={"noreferrer"}
            className="disable-long-press"
            href="https://play.google.com/store/apps/details?id=xyz.timelesswallet.aa&pli=1"
            target="_blank"
          >
            <img
              src={require("../../../../assets/images/tutorial/download-google.png")}
              alt="download-google"
              className="download-google"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const Step2 = () => {
  return (
    <div className="step-sub-title">
      <div style={{ marginBottom: 10 }} />
      <p>
        {`Create your wallet by following the video guide to the right and start your fun ride at Linea Park.`}
      </p>
      <div style={{ marginBottom: 16 }}>
        <div style={{ fontWeight: "bold" }}>{`Existing user? `}</div>
        <div>{`Linea Park is tailored for EOA wallet experience to save on gas fee. Avoid selecting the smart wallet (AA tag) option.`}</div>
      </div>
      <span>{`For additional info, reference the `}</span>
      <a
        target="_blank"
        href="https://timelessx.gitbook.io/timeless-x/help-center/linea-park-guide"
        rel="noreferrer"
        className="hyperlink"
      >
        {"Gitbook"}
      </a>
      <span>{`. If you require additional assistance, ping us on Telegram (@TimelessFrens).`}</span>
    </div>
  );
};

const Step3 = () => {
  return (
    <div className="step-sub-title">
      <div style={{ marginBottom: 10 }} />
      Funding your wallet is simple as 1, 2, 3.
      <div>1. Select the wallet</div>
      <div>2. Tap three-dot icon on the avatar to copy your address</div>
      <p>3. Send ETH to your wallet on Linea network</p>
      <div>
        <span style={{ fontWeight: "bold" }}>Note: </span>
        <span>Send ETH to your wallet ONLY on Linea network.</span>
      </div>
    </div>
  );
};

const Step4 = () => {
  const xLink = "https://x.com/timelesswallet";
  const telegramLink = "https://t.me/timelessfrens";

  return (
    <Fragment>
      <div className="step-sub-title">
        <div style={{ marginBottom: 10 }} />
        <span>Follow us on </span>
        <a
          href={xLink}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: "bold" }}
        >
          X{" "}
        </a>
        <span>for the latest updates and news. Have questions? Ping us on</span>
        <a
          href={telegramLink}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: "bold" }}
        >
          {" "}
          Telegram!
        </a>
      </div>
      <div className="social-button-container">
        <a
          rel="noreferrer"
          target="_blank"
          href={xLink}
          className="social-button"
        >
          <XLogo className="x-logo" />
          Follow
        </a>
        <a
          href={telegramLink}
          rel="noreferrer"
          target="_blank"
          className="social-button"
        >
          <TelegramLogo className="telegram-logo" />
          Join
        </a>
      </div>
    </Fragment>
  );
};

const Step5 = ({ onPressNext }) => {
  return (
    <Fragment>
      <div className="step-sub-title">
        <div style={{ marginBottom: 10 }} />
        Helpful pointers for the Timeless quests on Linea Park.
      </div>
      <button
        onClick={onPressNext}
        className="timeless-step-4-next-button"
        type="button"
      >
        Next
      </button>
    </Fragment>
  );
};

const Timeless = ({ tabActive, setTab }) => {
  const [type, setType] = useState("image");
  const [steps, setSteps] = useState([
    {
      id: 1,
      title: "Download Timeless X",
      content: <Step1 />,
      isVisible: true,
      src: require("assets/images/tutorial/timeless-step/step-1.png"),
      type: "image",
    },
    {
      id: 2,
      title: "Create a Wallet",
      content: <Step2 />,
      isVisible: false,
      src: require("assets/images/tutorial/timeless-step/import-wallet.mp4"),
      thumbnail: require("assets/images/tutorial/timeless-step/step-2.png"),
      type: "video",
    },
    {
      id: 3,
      title: "Fund Your Wallet",
      content: <Step3 />,
      isVisible: false,
      src: require("assets/images/tutorial/timeless-step/fund-your-wallet.mp4"),
      thumbnail: require("assets/images/tutorial/timeless-step/step-3.png"),
      type: "video",
    },
    {
      id: 4,
      title: "Follow X & Telegram",
      content: <Step4 />,
      isVisible: false,
      src: require("assets/images/tutorial/timeless-step/step-4.png"),
      type: "image",
    },
    {
      id: 5,
      title: "Continue to Linea Park",
      content: <Step5 onPressNext={() => setTab("Layer3")} />,
      isVisible: false,
      src: require("assets/images/tutorial/timeless-step/step-5.png"),
      type: "image",
    },
  ]);

  const changeStep = (clickedIndex) => {
    const updatedSteps = steps.map((step, index) => {
      return {
        ...step,
        isVisible: index === clickedIndex,
      };
    });
    const visibleStep = updatedSteps.find((step) => step.isVisible);

    if (visibleStep) {
      setType(visibleStep.type);
    }
    setSteps(updatedSteps);
  };

  const renderVisibleImage = () => {
    const visibleStep = steps.find((step) => step.isVisible);

    if (visibleStep.type === "video")
      return { src: visibleStep.src, thumbnail: visibleStep.thumbnail };

    if (visibleStep.type === "image") {
      return { src: visibleStep.src };
    }

    return null;
  };

  return (
    <div className="timeless-tab">
      <div className="left">
        <Tab tabActive={tabActive} setTab={setTab} />
        <Steps
          steps={steps}
          changeStep={(index) => {
            changeStep(index);
          }}
        />
      </div>
      <div className="right">
        {type === "video" && (
          <VideoIphoneContainer
            url={renderVisibleImage().src}
            thumbnail={renderVisibleImage().thumbnail}
            isRenderModal={false}
          />
        )}
        {type === "image" && (
          <ImageContainer
            src={renderVisibleImage().src}
            alt="iphone-1"
            className="iphone"
          />
        )}
      </div>
    </div>
  );
};

export default Timeless;

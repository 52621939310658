import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Steps = ({ steps, changeStep }) => {
  return (
    <div className="steps fade-in hidden-scroll-bar">
      {steps.map((step, index) => {
        return (
          <StepItem
            step={step}
            changeStep={changeStep}
            index={index}
            key={step.id}
          />
        );
      })}
    </div>
  );
};

export default Steps;

const StepItem = ({ step, changeStep, index }) => {
  const ref = useRef(null);
  const [width, height] = useWindowSize();

  const animateHeight = (isVisible) => {
    const element = ref.current;

    if (element) {
      const content = element.querySelector(".step-content");

      if (isVisible) {
        // Only attempt to set height if this step has been hidden before
        if (element.querySelector(".step-content").classList.contains("d-none")) {
          const actualHeight = content.scrollHeight;
          element.querySelector(".step-content").classList.remove("d-none");

          requestAnimationFrame(() => {
            content.style.height = `${actualHeight}px`;
          });
        }
      } else {
        // content.style.height = `${content.scrollHeight}px`;
        element.querySelector(".step-content").classList.add("d-none");
        requestAnimationFrame(() => {
          content.style.height = "0";
        });
      }
    }
  };

  useEffect(() => {
    animateHeight(step.isVisible);
  }, [step.isVisible, width, height]);

  return (
    <div
      className={`step cursor-pointer ${step.isVisible ? "--active" : ""}`}
      key={step.id}
      onClick={() => changeStep(index)}
    >
      <div className="step-title" ref={ref}>
        <div className="number-circle">{index + 1}</div>
        <div className="title">
          {step.title}
          <div
            className="step-content"
            style={{ maxHeight: "max-content" }}
          >
            {step.content}
          </div>
        </div>
      </div>
    </div>
  );
};
